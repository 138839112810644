import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, concatMap, first, map, shareReplay, tap } from 'rxjs/operators';
import { EMPTY, Observable, of, Subject, zip } from 'rxjs';

import { IConsentCampaignItem } from '@consent/consent-campaign-item.model';
import { AuthService } from '@core/auth/auth.service';
import { IPaginationResponse } from '@core/models/pagination-response.model';
import { IBucket } from '@core/models/bucket.model';
import { IBucketFile } from '@core/models/bucketFile.model';
import { IDBConnection } from '@core/models/dbconnection.model';
import { ITreeviewItem } from '@core/models/treeview-item.model';
import { ISharepoint } from '@core/models/sharepoint.model';
import { IDataSource } from '@core/models/data-source.model';
import { IPagination } from '@core/models/pagination-params';
import { IPolicy } from '@core/models/policy.model';
import { IRule } from '@core/models/rules.model';
import { SubscriptionState } from '@core/models/subscription-state';
import { DatasetsAbstractService } from './datasets.abstract.service';
import { GridService } from '@features/oc-grid/grid.service';
import { environment } from 'src/environments/environment';
import { buildPaginationParams, getFileExtension } from '@shared/helpers/helpers';
import { ICustomFormOption } from '@shared/features/custom-forms/custom-form-option.model';
import { RecipientsService } from '@shared/services/recipients.service';
import { OcUserService } from '@shared/services/oc-user.service';

@Injectable({
  providedIn: 'root',
})
export class DatasetsService extends DatasetsAbstractService {
  private datasourceChange = new Subject<string | void>();
  datasourceChange$ = this.datasourceChange.asObservable();

  constructor(
    protected authService: AuthService,
    protected domSanitizer: DomSanitizer,
    protected http: HttpClient,
    protected gridService: GridService,
    protected ocUserService: OcUserService,
    protected recipientsService: RecipientsService
  ) {
    super();
  }

  get userOrganizationId$(): Observable<string> {
    return this.ocUserService.getUserId();
  }

  addDatasetImage = (datasource: IDataSource): IDataSource => {
    const { image } = datasource;
    return image ? { ...datasource, image: this.domSanitizer.bypassSecurityTrustUrl(`data:image/;base64,${image}`) } : datasource;
  };

  buildDataset = (datasource: IDataSource, actAs: string[]): IDataSource => {
    const datasetWithOwner = this.addOwned(datasource, actAs);
    return this.addDatasetImage(datasetWithOwner);
  };

  buildDatasets = (datasources: IDataSource[], actAs: string[]): IDataSource[] => {
    return datasources.map(datasource => {
      return this.buildDataset(datasource, actAs);
    });
  };

  createDatasource = (dataSourceFormdata: FormData): Observable<IDataSource> => {
    return this.http.post<IDataSource>(environment.DATASOURCES, dataSourceFormdata).pipe(tap(() => this.datasourceChange.next()));
  };

  deleteDatasource = (id: string): Observable<string> => {
    const url = environment.DATASOURCEDELETE.replace('{datasourceId}', id);
    return this.http.delete(url, { responseType: 'text' }).pipe(tap(() => this.datasourceChange.next(id)));
  };

  downloadS3File = (bucketFile: IBucketFile): Observable<any> =>
    this.http.post(environment.DOWNLOADS3FILES, bucketFile, { responseType: 'arraybuffer' });

  getDatasource = (datasourceId: string): Observable<IDataSource> => {
    const url = environment.DATASOURCE.replace('{datasourceId}', datasourceId);
    return this.http.get<IDataSource>(url);
  };

  getDatasources = (params?: IPagination, options = {}): Observable<IPaginationResponse<IDataSource>> => {
    const url = environment.DATASOURCES;
    return this.http.get<IPaginationResponse<IDataSource>>(`${url}${buildPaginationParams(params)}`, options);
  };

  getDatasourcesOptions = (): Observable<ICustomFormOption<string>[]> => {
    return this.getCachedDatasets().pipe(
      map(response => {
        return response
          .filter(datasources => datasources.fields?.length > 1)
          .map(datasource => {
            return { value: datasource.id, label: datasource.name };
          });
      })
    );
  };

  getDataset = (datasourceId: string) => {
    const actAs$ = this.authService.userActAs$;
    return actAs$.pipe(
      concatMap(actAs => {
        return this.getDatasource(datasourceId).pipe(
          map(datasource => {
            return this.buildDataset(datasource, actAs);
          })
        );
      })
    );
  };

  getDatasetConsentCampaigns = (datasetId: string): Observable<IConsentCampaignItem[]> => {
    const url = environment.CONSENTCAMPAIGNSLIST.replace('{datasetId}', datasetId);
    return this.http.get<IConsentCampaignItem[]>(url);
  };

  getDatasetDetail = (datasourceId: string) => {
    const consentCampaigns$ = this.getDatasetConsentCampaigns(datasourceId).pipe(
      catchError(() => {
        return of([]);
      })
    );
    const recipients$ = this.recipientsService.getRecipients().pipe(
      map(recipients => {
        const { groups, organizations } = recipients;
        return [...groups, ...organizations];
      })
    );
    const dataset$ = this.getDataset(datasourceId).pipe(first());
    return recipients$.pipe(
      concatMap(recipients => {
        return zip(dataset$, consentCampaigns$).pipe(
          map(response => {
            const [dataset, consentCampaigns] = response;
            const { policies, 'policy-subscribed-ids': policySubscribedIds } = dataset;
            const newPolicies = this.getPoliciesWithRecipientsName(policies, recipients, policySubscribedIds);
            return {
              ...dataset,
              policies: newPolicies,
              consentCampaigns: this.getConsentCampaignWithRecipientNames(consentCampaigns, recipients),
            };
          })
        );
      }),
      catchError(e => {
        console.error(e);
        return EMPTY;
      })
    );
  };

  getRecipientsNameList = (recipientsIds: string[], recipients: ICustomFormOption<string>[]): string[] => {
    return recipientsIds.length
      ? recipientsIds.map(recipient => {
          const matchingRecipient = recipients.find(item => item.value === recipient);
          return matchingRecipient ? matchingRecipient.label : '';
        })
      : [];
  };

  getConsentCampaignWithRecipientNames = (
    consentCampaigns: IConsentCampaignItem[],
    recipientList: ICustomFormOption<string>[]
  ): IConsentCampaignItem[] => {
    const mappedConsentCampaigns = consentCampaigns.map(consentCampaign => {
      const { recipients } = consentCampaign;
      return { ...consentCampaign, recipients: this.getRecipientsNameList(recipients, recipientList) };
    });
    return mappedConsentCampaigns;
  };

  getPoliciesWithRecipientsName = (
    policies: IPolicy[],
    recipients: ICustomFormOption<string>[],
    policySubscribedIds: string[]
  ): IPolicy[] => {
    const newPolicies = [];
    (policies || []).forEach(policy => {
      const { rules } = policy;
      const totalShareWith = this.getShareWith(rules);
      const recipientsName = recipients
        .filter(recipient => {
          return totalShareWith.has(recipient.value);
        })
        .map(recipient => {
          return recipient.label;
        });
      newPolicies.push({ ...policy, recipientsName, susbcriptionSate: this.getSubscriptionState(policy, policySubscribedIds) });
    });

    return newPolicies;
  };

  getSubscriptionState = (policy: IPolicy, policySubscribedIds: string[]): SubscriptionState => {
    const { id, subscriptionState } = policy;
    if (subscriptionState) {
      return subscriptionState;
    } else {
      return policySubscribedIds.includes(id) ? SubscriptionState.SUBSCRIBED : SubscriptionState.PENDING;
    }
  };

  getShareWith = (rules: IRule[] = []): Set<string> => {
    const totalShareWith = new Set<string>();
    rules.forEach(rule => {
      rule.shareWith.forEach(shareName => totalShareWith.add(shareName));
    });
    return totalShareWith;
  };

  getDatasets = (params?: IPagination, options = {}): Observable<IPaginationResponse<IDataSource>> => {
    const actAs$ = this.authService.userActAs$;
    return actAs$.pipe(
      concatMap(actAs => {
        return this.getDatasources(params, options).pipe(
          map(response => {
            const { list, totalPages } = response;
            return { list: list ? this.buildDatasets(response.list, actAs) : [], totalPages };
          }),
          catchError(() => {
            return of({ list: [], totalPages: 0 });
          })
        );
      })
    );
  };

  getCachedDatasets = (params?: IPagination, options = {}): Observable<IDataSource[]> => {
    if (!this.cachedDatasources$) {
      const actAs$ = this.authService.userActAs$;
      this.cachedDatasources$ = actAs$.pipe(
        concatMap(actAs => {
          return this.getDatasources(params, options).pipe(
            map(response => {
              return response?.list ? this.buildDatasets(response.list, actAs) : [];
            }),
            catchError(() => {
              return of([]);
            })
          );
        }),
        shareReplay()
      );
    }
    return this.cachedDatasources$;
  };

  getDBTables = (dbConnection: IDBConnection): Observable<string[]> => this.http.post<string[]>(environment.GETDBTABLES, dbConnection);

  getDBTableColumns = (dbConnection: IDBConnection): Observable<string[]> =>
    this.http.post<string[]>(environment.GETDBTABLESCOLUMS, dbConnection);

  getS3Files = (dbConnection: IDBConnection): Observable<IBucket[]> => {
    return this.http.post<IBucket[]>(environment.GETS3FILES, dbConnection);
  };

  getFolder = (dbConnection: IDBConnection, options = {}): Observable<ITreeviewItem[]> => {
    return this.http.post<ITreeviewItem[]>(environment.GETSHAREPOINTFOLDERS, dbConnection, options);
  };

  getSites = (dbConnection: IDBConnection, options = {}): Observable<ISharepoint[]> => {
    return this.http.post<ISharepoint[]>(environment.GETSHAREPOINTSITES, dbConnection, options);
  };

  getRoot = (dbConnection: IDBConnection, options = {}): Observable<ITreeviewItem> => {
    return this.http
      .post<ITreeviewItem>(environment.GETSHAREPOINTROOT, dbConnection, options)
      .pipe(map(root => ({ ...root, isRoot: true })));
  };

  getSharepointAuth = (dbConnection: IDBConnection, options = {}): Observable<boolean> => {
    return this.http.post<boolean>(environment.GETSHAREPOINTAUTH, dbConnection, options);
  };

  getFileFields(file: File): Observable<any> {
    if (getFileExtension(file.name) === 'pdf') {
      return of(this.getDefaultFields());
    } else {
      const reader = new FileReader();
      return new Observable(observer => {
        reader.onload = () => {
          const data = reader.result;
          const levels = this.gridService.getLevels(data);
          observer.next(levels);
        };
        reader.readAsBinaryString(file);
      });
    }
  }

  resetDatasourcesCache = (): void => {
    this.cachedDatasources$ = null;
  };
}
